import React from "react"
import moment from "moment"
import { Link } from "gatsby"
import LocationIcon from "../../images/icons/SSLLC_Icons_Location_Gray-1.svg"

moment().format()

const AuctionSummary = (props) => {
  var startDate = new Date(props.startDate.seconds * 1000)
  var endDate = new Date(props.endDate.seconds * 1000)

  function formatDateRange(startDate, endDate) {
    let startDateMoment = moment(startDate)
    let endDateMoment = moment(endDate)
    const sameMonth = startDateMoment.month() === endDateMoment.month()
    const sameYear = startDateMoment.year() === endDateMoment.year()

    if (sameMonth && sameYear) {
      return `${startDateMoment.format("MMMM D")}-${endDateMoment.format(
        "D, YYYY"
      )}`
    } else if (sameYear) {
      return `${startDateMoment.format("MMMM D")}-${endDateMoment.format(
        "MMMM D, YYYY"
      )}`
    } else {
      return `${startDateMoment.format(
        "MMMM D, YYYY"
      )} - ${endDateMoment.format("MMMM D, YYYY")}`
    }
  }
  return (
    <>
      <div className="w-full h-full flex flex-col justify-between">
        <div
          className={`rounded-[15px] text-left flex flex-col justify-start mt-[15px] h-full bg-white
        ${props.isPastAuction ? "shadow-gray-top" : "shadow-red-top"}`}
        >
          <div className="w-full shadow-custom-image">
            <Link
              className="block"
              title={props.title}
              to={`/auction${props.slug}`}
            >
              <img
                alt={props.title}
                className="no-border rounded-t-[15px] shadow-custom-out-b h-60 transition duration-300"
                style={{
                  filter: props.isPastAuction ? "grayscale(100%)" : "none",
                  transition: "filter 300ms",
                }}
                onMouseEnter={(e) => {
                  if (props.isPastAuction) {
                    e.currentTarget.style.filter = "none"
                  }
                }}
                onMouseLeave={(e) => {
                  if (props.isPastAuction) {
                    e.currentTarget.style.filter = "grayscale(100%)"
                  }
                }}
                src={props.summaryImageUrl}
              />
            </Link>
          </div>
          <div className="p-6 flex flex-col flex-grow justify-between items-start">
            <h4 className="text-[1.5rem] xl:text-[1.675] text-balance mb-0">
              {props.title}
            </h4>
            <p
              className={`font-semibold text-[1.35rem]
              ${props.isPastAuction ? "text-medium-gray" : "text-red"}
              `}
            >
              {formatDateRange(startDate, endDate)}
            </p>
            <div className="text-medium-gray flex flex-row items-start space-x-2 pb-6">
              <img
                className="inline-block mr-2"
                src={LocationIcon}
                width={15}
                height={15}
                alt="A gray GPS pin"
              />
              {props.websiteLocation}
            </div>

            {!props.isPastAuction && (
              <div className="pb-6">
                <Link
                  className="bg-light-gray hover:bg-gray py-2 px-4 text-charcoal hover:text-charcoal rounded-[4px] transition duration-300 text-[1.2rem] font-normal"
                  to={`/auction${props.slug}`}
                >
                  Learn More
                </Link>
              </div>
            )}
            <div className="pb-2">
              <a
                href={
                  props.viewLotUrl.startsWith("https://ssllc.com/")
                    ? `../../${props.viewLotUrl.replace(
                        "https://ssllc.com/",
                        ""
                      )}`
                    : props.viewLotUrl
                }
                className={`py-2 px-4 text-white hover:text-white rounded-[4px] transition duration-300 text-[1.2rem] font-normal
        ${
          props.isPastAuction
            ? "bg-medium-gray hover:bg-royal-blue"
            : "bg-red hover:bg-scarlet-red"
        }`}
              >
                View Auction Catalog
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AuctionSummary
