import React from "react"
import { Link, graphql } from "gatsby"
import { auctionsFaqItems } from "../utils/data/faq"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import AuctionSummary from "../components/auctions/auction-summary"
import FAQ from "../components/faq-components/faq"
import ContactForm from "../components/bodycomponents/contact-form"
import TopHeroComponent from "../components/herocomponents/topherocomponent"
import RED_BG from "../images/background-images/bg-red.svg"
import M_GRAY_BG from "../images/background-images/bg-medium-gray.svg"

import { colors } from "../utils/colors"

import AllerganLogo from "../images/clients/Allergan-Logo.png"
import AnokionLogo from "../images/clients/Anokion-Logo.png"
import BiodelLogo from "../images/clients/Biodel-AG-Logo.jpg"
import ExactSciencesLogo from "../images/clients/Exact-Sciences-Logo.png"
import LexiconPharmaLogo from "../images/clients/Lexicon-Pharma-Logo.png"
import NovoNordiskLogo from "../images/clients/Novo-Nordisk-Logo.png"
import SangartLogo from "../images/clients/Sangart-Logo.jpeg"
import SynlogicLogo from "../images/clients/Synlogic-Logo.png"
import TechnovaxLogo from "../images/clients/Technovax-Logo.jpg"
import ThermoFisherLogo from "../images/clients/Thermo-Fisher-Scientific-Logo.png"
import ViaCyteLogo from "../images/clients/ViaCyte-Logo.jpg"
import SanofiLogo from "../images/clients/Sanofi-Logo.png"
import GEA from "../images/clients/GEA-Logo.png"

//Industries We Serve
import BiotechnologyIcon from "../images/icons/SSLLC Icons_Auctions_Biotech.svg"
import PharmaIcon from "../images/icons/SSLLC Icons_Auctions_Pharma.svg"
import ChemicalIcon from "../images/icons/SSLLC Icons_Auctions_Chemical.svg"
import PlasticsIcon from "../images/icons/SSLLC Icons_Auctions_Plastics.svg"
import FoodBeverageIcon from "../images/icons/SSLLC Icons_Auctions_Food & Bev 1.svg"
import PackagingIcon from "../images/icons/SSLLC Icons_Auctions_Packaging 1.svg"
import ExtractionIcon from "../images/icons/SSLLC Icons_Auctions_Extration & Growing.svg"

class Auctions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visibleAuctions: 6, // Default for SSR
    }

    this.updateVisibleAuctions = this.updateVisibleAuctions.bind(this)
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      this.updateVisibleAuctions()
      window.addEventListener("resize", this.updateVisibleAuctions)
    }
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.updateVisibleAuctions)
    }
  }

  updateVisibleAuctions() {
    if (window.innerWidth < 640) {
      this.setState({ visibleAuctions: 3 })
    } else {
      this.setState({ visibleAuctions: 6 })
    }
  }

  showMore = () => {
    this.setState({ visibleAuctions: this.props.data.allAuction.edges.length })
  }

  render() {
    const { data } = this.props
    const { visibleAuctions } = this.state

    var futureAuctions = data.allAuction.edges
      .filter(({ node }) => {
        let endDate = new Date(node.endDate.seconds * 1000).setHours(0, 0, 0, 0)
        if (endDate >= new Date().setHours(0, 0, 0, 0)) return node
      })
      .reverse()
    var pastAuctions = data.allAuction.edges
      .filter(({ node }) => {
        let endDate = new Date(node.endDate.seconds * 1000).setHours(0, 0, 0, 0)
        if (endDate < new Date().setHours(0, 0, 0, 0)) return node
      })
      .slice(0, 15)

    return (
      <>
        <Layout>
          <SEO
            title={`Equipment Auctions | Maximize Value with Surplus Solutions`}
            description={`At Surplus Solutions, our online auctions deliver maximum returns with quick turnaround times. We help clients find the right auction—get in touch today!`}
          />
          <TopHeroComponent
            backgroundImage={RED_BG}
            backgroundColor={colors.scarletRed}
            fontShadowColor={colors.red}
            shadowColor="shadow-red-bottom"
            pageTitle="Surplus Solutions Auctions"
            title="Exclusive Auctions: Lab Essentials & More"
          />
          <div className="shadow-custom-out">
            <div className="container max-w-screen-xl pt-14 pb-10">
              <div className="">
                <p className="">
                  Surplus Solutions offers live online auctions with unbeatable
                  deals on top-quality used lab, packaging, and processing
                  equipment. Browse a wide selection from leading manufacturers,
                  with new items added frequently—perfect for single
                  replacements or complete facility setups.
                </p>
                {/* <div className="flex justify-end">
                <a
                  href="/timed-auctions/#!/login/"
                  className="bg-red hover:bg-scarlet-red py-2 px-4 text-white hover:text-white rounded-[4px] transition duration-300 text-[0.875rem]"
                >
                  Login to My Account
                </a>
              </div> */}
              </div>
            </div>
          </div>

          <div className="bg-container-gray">
            <div className="container max-w-screen-xl py-14">
              <h2 className="mb-0 pb-14">Upcoming Auctions</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16">
                {futureAuctions.map(({ node }) => (
                  <div key={node.id}>
                    <AuctionSummary {...node} />
                  </div>
                ))}
              </div>
              {futureAuctions.length === 0 ? (
                <div>
                  No upcoming auctions at this time
                  <br />
                  <br />
                  <br />
                </div>
              ) : null}
            </div>
          </div>
          <div className="bg-light-gray shadow-custom-in">
            <div className="container max-w-screen-xl py-14">
              <h2 className="mb-0 pb-14">Past Auctions</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16">
                {pastAuctions.slice(0, visibleAuctions).map(({ node }) => (
                  <div key={node.id}>
                    <AuctionSummary {...node} isPastAuction={true} />
                  </div>
                ))}
                {visibleAuctions < pastAuctions.length && (
                  <div className="col-span-full justify-center text-center">
                    <button
                      onClick={this.showMore}
                      className="px-4 py-2 bg-primary text-white hover:text-white rounded-[4px] bg-medium-gray hover:bg-red transition duration-300"
                    >
                      Show More
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="shadow-custom-out">
            <div className="container max-w-screen-xl py-8">
              <div className="w-full py-10 text-center">
                <h2 className="text-3xl font-bold mb-4">Industries We Serve</h2>
                <p className="pb-10 text-pretty">
                  At Surplus Solutions, we cater to a broad range of industries,
                  providing customized solutions for all new, unused, and used
                  equipment needs.
                </p>
                <div className="grid grid-cols-2 md:grid-cols-7 justify-items-center">
                  <div className="flex flex-col items-center">
                    <img
                      src={BiotechnologyIcon}
                      alt="A cartoon image of a microscope and DNA double helix"
                      className="h-20 mb-4"
                    />
                    <h5>Biotechnology</h5>
                  </div>
                  <div className="flex flex-col items-center">
                    <img
                      src={PharmaIcon}
                      alt="A cartoon image of a pharmaceutical pill bottle next to a medical capsule"
                      className="h-20 mb-4"
                    />
                    <h5>Pharma</h5>
                  </div>
                  <div className="flex flex-col items-center">
                    <img
                      src={ChemicalIcon}
                      alt="A cartoon image of a glass flask containing bubbling blue liquid"
                      className="h-20 mb-4"
                    />
                    <h5>Chemical</h5>
                  </div>
                  <div className="flex flex-col items-center">
                    <img
                      src={PlasticsIcon}
                      alt="A cartoon image of three arrows arranged in a triangle, pointing towards each other"
                      className="h-20 mb-4"
                    />
                    <h5>Plastics</h5>
                  </div>
                  <div className="flex flex-col items-center">
                    <img
                      src={FoodBeverageIcon}
                      alt="A cartoon image of a fork, knife, and spoon"
                      className="h-20 mb-4"
                    />
                    <h5>Food & Beverage</h5>
                  </div>
                  <div className="flex flex-col items-center">
                    <img
                      src={PackagingIcon}
                      alt="A cartoon image of a punch-through pill package next to a cardboard shipping box"
                      className="h-20 mb-4"
                    />
                    <h5>Packaging</h5>
                  </div>
                  <div className="flex flex-col col-span-2 md:col-span-1 items-center justify-items-center">
                    <img
                      src={ExtractionIcon}
                      alt="A cartoon image of a leaf and a dropper containing green liquid"
                      className="h-20 mb-4"
                    />
                    <h5>Extraction & Growing Tech</h5>
                  </div>
                </div>
              </div>
              <hr className="border-gray" />

              <div className="w-full py-10 text-center">
                <h2>Our Clients</h2>
                <p className="pb-10 text-pretty">
                  Surplus Solutions proudly serves world-renowned companies
                  across many industries, such as pharmaceutical, biotechnology,
                  and others. Our industry-leading clientele reflects our
                  dedication to providing first-class lab equipment solutions.
                </p>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-16 justify-items-center items-center pb-16">
                  <img
                    src={ThermoFisherLogo}
                    alt="Thermo Fisher Scientific Logo"
                    className="h-20 object-contain w-full max-w-[150px] md:max-w-[180px]"
                  />
                  <img
                    src={SanofiLogo}
                    alt="Sanofi Logo"
                    className="h-20 object-contain w-full max-w-[150px] md:max-w-[180px]"
                  />
                  <img
                    src={AllerganLogo}
                    alt="Allergan Logo"
                    className="h-20 object-contain w-full max-w-[150px] md:max-w-[180px]"
                  />
                  <img
                    src={BiodelLogo}
                    alt="Biodel AG Logo"
                    className="h-20 object-contain w-full max-w-[150px] md:max-w-[180px]"
                  />
                  <img
                    src={LexiconPharmaLogo}
                    alt="Lexicon Pharma Logo"
                    className="h-20 object-contain w-full max-w-[150px] md:max-w-[180px]"
                  />
                  <img
                    src={SangartLogo}
                    alt="Sangart Logo"
                    className="h-20 object-contain w-full max-w-[150px] md:max-w-[180px]"
                  />
                  <img
                    src={AnokionLogo}
                    alt="Anokion Logo"
                    className="h-20 object-contain w-full max-w-[150px] md:max-w-[180px]"
                  />
                  <img
                    src={NovoNordiskLogo}
                    alt="Novo Nordisk Logo"
                    className="h-20 object-contain w-full max-w-[150px] md:max-w-[180px]"
                  />
                  <img
                    src={GEA}
                    alt="GEA Logo"
                    className="h-16 object-contain"
                  />
                  <img
                    src={ExactSciencesLogo}
                    alt="Exact Sciences Logo"
                    className="h-20 object-contain w-full max-w-[150px] md:max-w-[180px]"
                  />
                  <img
                    src={TechnovaxLogo}
                    alt="Technovax Logo"
                    className="h-20 object-contain w-full max-w-[150px] md:max-w-[180px]"
                  />
                  <img
                    src={SynlogicLogo}
                    alt="Synlogic Logo"
                    className="h-20 object-contain w-full max-w-[150px] md:max-w-[180px]"
                  />
                </div>
                <div className="grid grid-cols-1 justify-items-center items-center">
                  <img
                    src={ViaCyteLogo}
                    alt="ViaCyte Logo"
                    className="h-12 object-contain w-full max-w-[150px] md:max-w-[180px]"
                  />
                </div>
              </div>
            </div>
          </div>
          <FAQ
            title="Auctions FAQs"
            items={auctionsFaqItems}
            backgroundColor={colors.lightGray}
            backgroundImage={M_GRAY_BG}
            shadowColor="shadow-mediumGray-top"
          />
          <ContactForm
            title="Never miss a great deal!"
            body={
              <p>
                Sign up for auction alerts and never miss out on the best find!
              </p>
            }
            fontShadowColor={colors.red}
            backgroundColor={colors.containerGray}
            shadow="shadow-red-bottom"
            formType="Auction Alerts"
          />
        </Layout>
      </>
    )
  }
}

export const query = graphql`
  query {
    allAuction(sort: { order: DESC, fields: startDate___seconds }) {
      totalCount
      edges {
        node {
          title
          blurb
          startDate {
            seconds
          }
          endDate {
            seconds
          }
          slug
          location
          websiteLocation
          viewLotUrl
          summaryImageUrl
        }
      }
    }
  }
`

export default Auctions
